/**
* Establece el color del borde del banner
* @param {string} status - Estatus
*/
function statusBorderStyle(status) {
  switch (status) {
    case 'Completada':
      return 'sm-banner-alert-bordered'
    case 'Traspasada':
      return 'sm-banner-alert-bordered'
    default:
      return 'sm-banner-alert'
  }
}

function modelBorderStyle(model) {
  switch (model) {
    case 'Orden':
      return 'banner-alert-bordered'
    default:
      return 'banner-alert'
  }
}

/**
* Establece el color de banner
* @param {string} status - Estatus
*/
function statusColorStyle(status) {
  switch (status) {
    case 'Aprobada':
    case 'Bodega CDMX':
    case 'Pago liquidado':
    case 'Pagado':
    case 'Solicitud':
      return 'alert-green'
    case 'Lista para entrega':
    case 'Tienda':
      return 'alert-yellow'
    case 'Traspaso en proceso':
      return 'alert-yellow'
    case 'Entrega completa':
    case 'Bodega GDL':
      return 'alert-blue'
    case 'Entrega parcial':
    case 'Pendiente':
    case 'Por pagar':
      return 'alert-cyan'
    case 'En bodega':
      return 'alert-purple'
    case 'Detenida':
    case 'Cancelada':
    case 'Cancelado':
      return 'alert-gray'
    case 'Completada':
      return 'alert-green-white'
    case 'En suministro':
    case 'Rechazado':
      return 'alert-orange'
    case 'Traspaso':
      return 'alert-marine'
    case 'Traspaso aprobado':
      return 'alert-green'
    case 'Traspasada':
      return 'alert-green-white'
    default:
      return 'alert-blue'
  }
}

export { statusBorderStyle, statusColorStyle, modelBorderStyle }
