<template>
  <validation-observer ref="commissionResumenForm">
    <div class="p-2 d-flex align-items-start flex-column vh-100">
      <div class="mt-5">
        <b-form-row>

          <!-- #region::Title -->
          <b-col>
            <h4 class="font-weight-bolder">
              {{ resumenTitle }}
            </h4>
          </b-col>
          <!-- #endregion::Title -->

          <b-col md="12">
            <hr class="my-1">
          </b-col>

          <!-- #region::Subtotal sales -->
          <b-col>
            <div class="d-flex justify-content-between">
              <span>Subtotal venta</span>
              <span>{{ commissionSubtotalSelling | currency }}</span>
            </div>
          </b-col>
          <!-- #endregion::Subtotal sales -->

          <b-col md="12">
            <hr class="my-1">
          </b-col>

          <!-- #region::Refund -->
          <b-col>
            <div class="d-flex justify-content-between">
              <span>Devolución</span>
              <span>{{ commissionRefund | currency }}</span>
            </div>
          </b-col>
          <!-- #endregion::Refund -->

          <b-col md="12">
            <hr class="my-1">
          </b-col>

          <!-- #region::Total sale -->
          <b-col>
            <div class="d-flex justify-content-between">
              <span>Total venta</span>
              <span>{{ commissionTotalSelling | currency }}</span>
            </div>
          </b-col>
          <!-- #endregion::Total sale -->

          <b-col md="12">
            <hr class="my-1">
          </b-col>

          <!-- #region::Commission percentage -->
          <b-col>
            <div
              v-if="showVoucherOptions"
              class="d-flex justify-content-between"
            >
              <span>Porcentaje comisión</span>
              <span>{{ isValidPercentage(commissionPercentage) }}</span>
            </div>
            <b-row
              v-else
              align-h="between"
            >
              <b-col
                md="7"
                class="align-self-center"
              >
                <h5>Porcentaje comisión</h5>
              </b-col>
              <b-col md="5">
                <TextInputWithValidation
                  v-model="commissionPercentage"
                  vid="commissionPercentage"
                  rules="required|negative|notalpha|decimal|max_value:999.99"
                  type="text"
                  name="descuento"
                  maxlength="6"
                  placeholder="0.00"
                  append="%"
                  :styled="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- #endregion::Commission percentage -->

          <b-col md="12">
            <hr class="my-1">
          </b-col>

          <!-- #region::Total commision -->
          <b-col>
            <div class="d-flex justify-content-between">
              <h5 class="font-weight-bolder">
                Total comisión
              </h5>
              <h5 class="font-weight-bolder">
                {{ commissionTotal | currency }}
              </h5>
            </div>
          </b-col>
          <!-- #endregion::Total commision -->

          <!-- #region::Voucher options -->
          <template v-if="showVoucherOptions">
            <b-col md="12">
              <hr class="my-1">
            </b-col>

            <!-- #region::Title -->
            <b-col>
              <h4 class="font-weight-bolder">
                Comprobante de pago de la comisión
              </h4>
            </b-col>
            <!-- #endregion::Title -->

            <b-col md="12">
              <hr class="my-1">
            </b-col>

            <!-- #region::Options for loading voucher image -->
            <template v-if="!isVoucherImageLoaded">

              <!-- #region::Preview icon -->
              <b-col
                cols="12"
                class="text-center"
              >
                <p>
                  <feather-icon
                    icon="FileTextIcon"
                    class="feather-48"
                  />
                </p>
                <span>Aquí se mostrará el recibo cuando lo agregues</span>
              </b-col>
              <!-- #endregion::Preview icon -->

              <!-- #region::Add voucher button -->
              <b-col
                cols="12"
                class="text-center mt-1"
              >
                <b-button
                  variant="principal-btn"
                  class="principal-btn w-100"
                  :disabled="isCommissionCanceled"
                  @click="$refs.voucherImageModal.showModal(commission)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Agregar comprobante</span>
                </b-button>
              </b-col>
            <!-- #endregion::Add voucher button -->

            </template>
            <!-- #endregion::Options for loading voucher image -->

            <!-- #region::Options for managing voucher image -->
            <template v-else>

              <!-- #region::File name -->
              <b-col
                class="d-flex"
                cols="12"
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="align-self-center mr-50"
                />
                <span>{{ commission.image_name }}</span>
              </b-col>
              <!-- #endregion::File name -->

              <!-- #region::Options buttons -->
              <b-col
                class="d-flex pl-2 ml-50 my-75"
                cols="12"
              >

                <!-- #region::Preview image button -->
                <b-button
                  v-b-tooltip.hover.top="'Ver recibo'"
                  variant="flat-secondary"
                  class="btn-icon p-25"
                  @click="$refs.imagePreviewModal.show(commission.url)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="18"
                    class="text-primary"
                  />
                </b-button>
                <!-- #endregion::Preview image button -->

                <!-- #region::Download receipt button -->
                <b-button
                  v-b-tooltip.hover.top="'Descargar recibo'"
                  variant="flat-secondary"
                  class="btn-icon p-25"
                  @click="downloadVoucherImage({
                    commissionId: commission.commission_id,
                    fileName: commission.image_name
                  })"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="18"
                    class="text-success"
                  />
                </b-button>
                <!-- #endregion::Download receipt button -->

                <!-- #region::Edit payment button -->
                <b-button
                  v-b-tooltip.hover.top="'Editar'"
                  variant="flat-secondary"
                  class="btn-icon p-25"
                  @click="$refs.voucherImageModal.showModalAndLoadVoucher()"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="18"
                    class="text-primary"
                  />
                </b-button>
                <!-- #endregion::Edit payment button -->

              </b-col>
              <!-- #endregion::Options buttons -->

              <!-- #region::Payment date -->
              <b-col
                class="d-flex justify-content-between"
                cols="12"
              >
                <span>Fecha de pago:</span>
                <span>{{ commission.payment | moment("DD/MM/YYYY") }}</span>
              </b-col>
              <!-- #endregion::Payment date -->

            </template>
            <!-- #endregion::Options for managing voucher image -->

          </template>
          <!-- #endregion::Voucher options -->

        </b-form-row>
      </div>
      <div
        v-if="!showVoucherOptions"
        class="mt-auto w-100"
      >
        <b-form-row>
          <b-col md="6">
            <b-button
              variant="delete-btn"
              class="delete-btn w-100"
              @click="onCancelSavingCommission"
            >
              Cancelar
            </b-button>
          </b-col>

          <b-col md="6">
            <b-button
              variant="principal-btn"
              class="principal-btn w-100 text-nowrap"
              @click="onSaveCommission"
            >
              {{ isCommissionEditing ? 'Guardar cambios' : 'Crear comisión' }}
            </b-button>
          </b-col>
        </b-form-row>

      </div>
    </div>

    <VoucherImageModal
      ref="voucherImageModal"
      @reload-commission="$emit('reload-commission')"
    />
    <ImagePreviewModal
      ref="imagePreviewModal"
      :title="'Comprobante de pago'"
    />

  </validation-observer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, localize, extend } from 'vee-validate'
import {
  required, decimal, notalpha, minValue, negative,
} from '@validations'
import {
  BFormRow, BCol, BRow, BButton, VBTooltip,
} from 'bootstrap-vue'

import getError from '@/helpers/ErrorsHandler'
import validatePercentage from '@/helpers/PercentageFormat'
import ImagePreviewModal from '@/components/modals/ImagePreviewModal.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VoucherImageModal from '@/modules/trade/commissions/components/modals/VoucherImageModal.vue'

const RESUMEN_TITLE = 'Resumen'
const EDIT_COMMISSION_TITLE = 'Editar comisión'
const NEW_COMMISSION_TITLE = 'Nueva comisión'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BFormRow,
    ImagePreviewModal,
    VoucherImageModal,
    ValidationObserver,
    TextInputWithValidation,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    showVoucherOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Validations
       */
      decimal,
      required,
      notalpha,
      minValue,
      negative,
    }
  },
  computed: {
    ...mapGetters({
      getCommission: 'commissions/getCommission',
      getCommissionTotal: 'commissions/getCommissionTotal',
      getCommissionRefund: 'commissions/getCommissionRefund',
      getCommissionOrdersIds: 'commissions/getCommissionOrdersIds',
      getIsCommissionEditing: 'commissions/getIsCommissionEditing',
      getCommissionRefundsIds: 'commissions/getCommissionRefundsIds',
      getCommissionPercentage: 'commissions/getCommissionPercentage',
      getCommissionTotalSelling: 'commissions/getCommissionTotalSelling',
      getSelectedCommissionOrders: 'commissions/getSelectedCommissionOrders',
      getSelectedCommissionSeller: 'commissions/getSelectedCommissionSeller',
      getSelectedCommissionRefunds: 'commissions/getSelectedCommissionRefunds',
      getCommissionSubtotalSelling: 'commissions/getCommissionSubtotalSelling',
      getSelectedCommissionMonthAndYear: 'commissions/getSelectedCommissionMonthAndYear',
    }),
    /**
     * Información de la comisión
     */
    commission: {
      get() { return this.getCommission },
    },
    /**
     * Total
     */
    commissionTotal: {
      get() { return this.getCommissionTotal },
    },
    /**
     * Devolución
     */
    commissionRefund: {
      get() { return this.getCommissionRefund },
    },
    /**
     * Identificadores de las órdenes
     */
    commissionOrdersIds: {
      get() { return this.getCommissionOrdersIds },
    },
    /**
     * Indica si el modo de edición está activo
     */
    isCommissionEditing: {
      get() { return this.getIsCommissionEditing },
    },
    /**
     * Identificadores de las devoluciones
     */
    commissionRefundsIds: {
      get() { return this.getCommissionRefundsIds },
    },
    /**
     * Porcentaje de comisión
     */
    commissionPercentage: {
      get() { return this.getCommissionPercentage },
      set(value) { this.setCommissionPercentage(value) },
    },
    /**
     * Total de venta
     */
    commissionTotalSelling: {
      get() { return this.getCommissionTotalSelling },
    },
    /**
     * Ordenes seleccionadas
     */
    selectedCommissionOrders: {
      get() { return this.getSelectedCommissionOrders },
    },
    /**
     * Devoluciones seleccionadas
     */
    selectedCommissionRefunds: {
      get() { return this.getSelectedCommissionRefunds },
    },
    /**
     * Subtotal de venta
     */
    commissionSubtotalSelling: {
      get() { return this.getCommissionSubtotalSelling },
    },
    /**
     * Filtro de creador (filtro en store)
     */
    filteredSeller: {
      get() { return this.getSelectedCommissionSeller },
    },
    /**
     * Filtro de mes y año (filtro en store)
     */
    filteredMonthAndYear: {
      get() { return this.getSelectedCommissionMonthAndYear },
    },
    /**
     * Estatus de la comisión
     */
    commissionStatus() {
      return this.getCommission.status
    },
    /**
     * Si el estatus del camisión es 'Cancelada' no se puede agregar
     * comprobante
     */
    isCommissionCanceled() {
      return this.commissionStatus === 'Cancelado'
    },
    /**
     * Determina si ya se ha cargado imagen de voucher
     */
    isVoucherImageLoaded() {
      return this.commission.url !== null
    },
    /**
     * Título del resumen
     */
    resumenTitle() {
      if (this.showVoucherOptions) {
        return RESUMEN_TITLE
      }

      if (this.isCommissionEditing) {
        return EDIT_COMMISSION_TITLE
      }

      return NEW_COMMISSION_TITLE
    },
  },

  /**
   * Hook que se ejecuta cuando se crea el componente
   * @summary Registra las validaciones personalizadas
   */
  created() {
    extend('max_value', {
      params: ['target'],
      validate(value, { target }) {
        return Number(value) <= Number(target)
      },
      message: 'El campo {_field_} solo puede tener 3 valores enteros y 2 decimales.',
    })

    localize('es')
  },
  methods: {
    ...mapActions({
      createCommission: 'commissions/createCommission',
      downloadVoucherImage: 'commissions/downloadVoucherImage',
      setCommissionPercentage: 'commissions/setCommissionPercentage',
    }),

    /**
     * Guarda la comisión
     * @summary Guarda la comisión en la base de datos. Revisa si existen órdenes seleccionadas y
     * si el formulario es válido para enviar la información al servidor.
     */
    async onSaveCommission() {
      // Verifica si existen órdenes seleccionadas
      if (this.selectedCommissionOrders.length === 0) {
        this.showToast('Error de validación', 'No existen órdenes seleccionadas', 'warning')
        return
      }

      if (!this.isCommissionEditing) {
        if (!this.filteredSeller) {
          this.showToast('Error de validación', 'No hay vendedor seleccionado.', 'warning')
          return
        }

        if (!this.filteredMonthAndYear) {
          this.showToast('Error de validación', 'No hay fecha seleccionada.', 'warning')
          return
        }
      }

      // Valida los campos del formulario
      const isValid = await this.$refs.commissionResumenForm.validate()

      if (isValid) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          // FormData que contiene los arreglos de identificadores de órdenes y devoluciones
          const formData = new FormData()
          formData.append('orders', JSON.stringify(this.commissionOrdersIds))
          formData.append('refunds', JSON.stringify(this.commissionRefundsIds))

          // Identificador del vendedor
          formData.append('seller', this.isCommissionEditing
            ? this.commission.user_id
            : this.filteredSeller.id)
          // Mes y año
          formData.append('month', this.isCommissionEditing
            ? this.commission.month
            : this.filteredMonthAndYear.format('YYYY-MM-DD'))
          // Subtotal de venta
          formData.append('subtotal', this.commissionSubtotalSelling)
          // Devolución
          formData.append('devolution', this.commissionRefund)
          // Total de venta
          formData.append('total', this.commissionTotalSelling)
          // Porcentaje de comisión
          formData.append('percentage', this.commissionPercentage)
          // Total de comisión
          formData.append('totalCommission', this.commissionTotal)

          if (this.isCommissionEditing) {
          // Identificador de la comisión
            formData.append('ID', this.commission.commission_id)
          }

          // Imprime la información del FormData
          // eslint-disable-next-line no-restricted-syntax
          for (const pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`)
          }

          // Crea la comisión y cacha posibles errores
          await this.createCommission(formData)
          this.showCreatingCommissionConfirmation('¡Los datos de la comisión se han guardado correctamente!', this.isCommissionEditing)
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },

    /**
     * Cancela el registro de la comisión
     */
    onCancelSavingCommission() {
      if (this.isCommissionEditing) {
        return this.$router.push({
          name: 'commission-details',
          params: { id: this.commission.commission_id },
        })
      }
      return this.$router.push({ name: 'commissions-home' })
    },

    /**
    * Ventana de confirmación
    *
    * @summary Muestra una ventana de confirmación cuando se ha registrado una cotizción
    * @param {string} message - Mensaje a mostrar en la ventana de confirmación
    */
    showCreatingCommissionConfirmation(message, toDetails) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (toDetails) {
            this.$router.push({
              name: 'commission-details',
              params: { id: this.commission.commission_id },
            })
          } else {
            this.$router.push({ name: 'commissions-home' })
          }
        }
      })
    },

    /**
     * Valida si el porcentaje es válido
     *
     * @summary Valida si el porcentaje es válido y se aplica el formato de porcentaje
     * @param {String} value - Valor a validar
     * @return {String} Valor con formato de porcentaje
     */
    isValidPercentage(value) {
      return validatePercentage(value)
    },

    /**
    * Mensaje de alerta
    * @summary Muestra un mensaje de alerta personalizado
    * @param {string} title - Título del mensaje
    * @param {string} text - Texto del mensaje
    * @param {string} variant - Tipo de mensaje
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .feather-48{
    width: 68px;
    height: 68px;
    background-color: #CCCCCC;
    padding: 13px;
    border-radius: 50px;
  }
</style>
