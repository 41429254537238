<template>
  <div>

    <!-- #region::title -->
    <b-form-row>
      <b-col cols="12">
        <h3>{{ title }}</h3>
      </b-col>
    </b-form-row>
    <!-- #endregion::title -->

    <!-- #region begin:: List options -->
    <b-form-row>

      <!-- #region::Searcher -->
      <b-col
        md="4"
        class="my-50"
      >
        <BasicSearcher
          ref="basic-searcher"
          placeholder="Buscar"
          :callback="handleSearch"
        />
      </b-col>
      <!-- #endregion end::Searcher -->

      <!-- #region::Required location select -->
      <b-col
        md="2"
        class="my-50"
      >
        <v-select
          v-model="selectedLocation"
          label="text"
          class="style-chooser"
          placeholder="Ubicación"
          :options="locations"
        />
      </b-col>
      <!-- #endregion::Required location select -->

    </b-form-row>
    <!-- #endregion::Searcher -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BFormRow, BCol } from 'bootstrap-vue'

import vSelect from 'vue-select'
import BasicSearcher from '@/components/forms/BasicSearcher.vue'

export default {
  components: {
    BCol,
    vSelect,
    BFormRow,
    BasicSearcher,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedLocation: null,
    }
  },
  computed: {
    ...mapGetters({
      getLocations: 'orders/getLocations',
    }),
    /**
     * Ubicaciones para el select
     */
    locations: {
      get() { return this.getLocations },
    },
  },
  mounted() {
    this.$watch('selectedLocation', location => {
      this.$emit('on:filter', { type: 'location', value: location !== null ? location.text : '' })
    })
  },
  methods: {
    handleSearch(searchTerm) {
      this.selectedLocation = null
      this.$emit('on:filter', { type: 'search', value: searchTerm })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.style-chooser {
  background: #ffffff;
}
</style>
