<template>
  <div>

    <template v-if="isLoadingDetails">
      <BasicSkeleton height="200px" />
      <BasicSkeleton height="250px" />
      <BasicSkeleton height="250px" />
    </template>

    <template v-else>
      <div class="commission-details">
        <CommissionsDetailsOptions @reload-commission="loadCommissionData" />

        <b-card class="mt-1 border border-dark shadow-none">
          <CommissionsSellerSearcherDetailsInfo
            :date="sellerDate"
            :name="sellerName"
            :email="sellerEmail"
          />
        </b-card>

        <CommissionsOrdersList
          ref="commissions-orders-list"
          :hide-checkbox="true"
        />

        <CommissionsRefundsList
          ref="commissions-refunds-list"
          class="mt-3"
          :hide-checkbox="true"
        />
      </div>

      <!-- #region::Resumen commision -->
      <div class="resumen-commission border border-dark shadow-none">
        <CommissionsResumenForm
          :show-voucher-options="true"
          @reload-commission="loadCommissionData"
        />
      </div>
      <!-- #endregion::Resumen commision -->
    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { BCard } from 'bootstrap-vue'

import CommissionsOrdersList from '@/modules/trade/commissions/components/lists/CommissionsOrdersList.vue'
import CommissionsResumenForm from '@/modules/trade/commissions/components/form/CommissionsResumenForm.vue'
import CommissionsRefundsList from '@/modules/trade/commissions/components/lists/CommissionsRefundsList.vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import CommissionsDetailsOptions from '@/modules/trade/commissions/components/CommissionsDetailsOptions.vue'
import CommissionsSellerSearcherDetailsInfo from '@/modules/trade/commissions/components/form/CommissionsSellerSearcherDetailsInfo.vue'

export default {
  components: {
    BCard,
    BasicSkeleton,
    CommissionsOrdersList,
    CommissionsRefundsList,
    CommissionsResumenForm,
    CommissionsDetailsOptions,
    CommissionsSellerSearcherDetailsInfo,
  },
  props: {
    /**
     * Identificador de la comisión
     */
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      /**
       * Indica si se esta cargando la información del detalle del recibo
       */
      isLoadingDetails: false,
    }
  },
  computed: {
    ...mapGetters({
      getCommission: 'commissions/getCommission',
    }),
    /**
     * Obtiene la fecha del vendedor
     */
    sellerDate() {
      return this.getCommission?.month
    },
    /**
     * Obtiene el nombre del vendedor
     */
    sellerName() {
      return this.getCommission?.seller
    },
    /**
     * Obtiene el correo del vendedor
     */
    sellerEmail() {
      return this.getCommission?.email_seller
    },
  },

  beforeDestroy() {
    // Limpia los datos de la comision
    this.$store.commit('commissions/setCommission', null)
    // Limpia el porcentaje de comisión
    this.$store.commit('commissions/setCommissionPercentage', 0)
    // Limpia las listas de items seleccionados
    this.$store.commit('commissions/clearSelectedItemsListsFromCommission')
  },

  /**
  * Hook que se ejecuta cuando se monta el componente
  *
  * @summary Load the quote data
  */
  async created() {
    await this.loadCommissionData()
  },

  methods: {
    /**
     * Carga la información de la comisión
     */
    async loadCommissionData() {
      this.isLoadingDetails = true
      await this.$store.dispatch('commissions/loadCommission', this.id)
      this.isLoadingDetails = false
    },
  },
}
</script>
