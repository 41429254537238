<template>
  <div>
    <CommissionsLitsFormFilters
      title="Órdenes"
      @on:filter="handleFilter"
    />

    <!-- #region::Skeleton -->
    <template v-if="isLoadingOrders">
      <BasicSkeleton height="400px" />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <vue-good-table
            v-if="availableOrders"
            id="orders-table"
            ref="commission-orders-table"
            class="vgt-table-checkbox-styled"
            style-class="vgt-table condensed align-text-bottom"
            :columns="columns"
            :rows="formattedOrders"
            :sort-options="{
              enabled: false,
            }"
            :row-style-class="rowStyleClassFn"
            compact-mode
          >

            <!-- #region::Empty response -->
            <div slot="emptystate">
              No hay órdenes para mostrar.
            </div>
            <!-- #endregion::Empty response -->

            <!-- #region::Column headers -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span>
                <h6 class="capitalize-col-hd">
                  <strong>{{ props.column.label }}</strong>
                </h6>
              </span>
            </template>
            <!-- #endregion::Column headers -->

            <!-- #region::Modifying rows cells -->
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- #region::Checkbox -->
              <div v-if="props.column.field === 'checkbox'">
                <b-form-checkbox
                  v-model="props.row.selected"
                  @change="onOrderChecked(props.row)"
                />
              </div>
              <!-- #endregion::Checkbox -->

              <!-- #region::Row number -->
              <div v-else-if="props.column.field === 'no'">
                <h6>{{ indexFrom + props.index }}</h6>
              </div>
              <!-- #endregion::Row number -->

              <!-- #region:Location cell -->
              <div v-else-if="props.column.field === 'location'">
                <template v-if="props.row.location">
                  <div
                    :class="[styleBorder(props.row.location), styleColor(props.row.location)]"
                    role="alert"
                    class="text-nowrap"
                    style="display: inline; font-size: 11px;"
                  >
                    {{ props.row.location }}
                  </div>
                </template>
                <span
                  v-else
                  class="text-nowrap"
                >Sin especificar</span>
              </div>
              <!-- #endregion:Location cell -->

              <!-- #region::ID cell -->
              <div
                v-else-if="props.column.field === 'ID'"
                style="font-size: 14px; margin-top: -1px;"
              >
                <router-link
                  :to="{ name: 'order-details', params: { id: props.row.ID } }"
                  target="_blank"
                >
                  {{ props.row.ID }}
                </router-link>
              </div>
              <!-- #endregion::ID cell -->

              <!-- #region:Payment status cell -->
              <div v-else-if="props.column.field === 'payment_status'">
                <template v-if="props.row.payment_status">
                  <div
                    :class="[styleBorder(props.row.payment_status), styleColor(props.row.payment_status)]"
                    role="alert"
                    class="text-nowrap"
                    style="display: inline; font-size: 11px;"
                  >
                    {{ props.row.payment_status }}
                  </div>
                </template>
                <h6
                  v-else
                  class="text-nowrap"
                >Sin especificar</h6>
              </div>
              <!-- #endregion:Payment status cell -->

              <!-- #region:Order status cell -->
              <div v-else-if="props.column.field === 'order_status'">
                <template v-if="props.row.order_status">
                  <div
                    :class="[styleBorder(props.row.order_status), styleColor(props.row.order_status)]"
                    role="alert"
                    class="text-nowrap"
                    style="display: inline; font-size: 11px;"
                  >
                    {{ props.row.order_status }}
                  </div>
                </template>
                <h6
                  v-else
                  class="text-nowrap"
                >Sin especificar</h6>
              </div>
              <!-- #endregion:Order status cell -->

              <!-- #region:Completed month cell -->
              <div v-else-if="props.column.field === 'completed_date'">
                <h6
                  v-if="props.row.completed_date "
                  class="text-nowrap"
                >{{ props.row.completed_date | moment("MMMM YYYY") | capitalize }}</h6>
                <h6
                  v-else
                  class="text-nowrap"
                >Sin especificar</h6>
              </div>
              <!-- #endregion:Completed month cell -->

              <!-- #region:Subtotal MXN cell -->
              <div v-else-if="props.column.field === 'subtotal_mxn'">
                <h6 class="text-nowrap font-weight-bolder">
                  {{ isValidCurrency(props.row.subtotal_mxn) }}
                </h6>
              </div>
              <!-- #endregion:Subtotal MXN cell -->

              <!-- #region::Custom general cell -->
              <div v-else>
                <h6 class="text-nowrap">
                  {{ props.formattedRow[props.column.field] }}
                </h6>
              </div>
            <!-- #endregion::Custom general cell -->

            </template>
            <!-- #endregion::Modifying rows cells -->

          </vue-good-table>

          <!-- #region::Alert when no circuits are available -->
          <b-alert
            v-if="!availableOrders"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron órdenes.</span>
            </div>
          </b-alert>
        <!-- #endregion::Alert when no circuits are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region::Pagination & items per list -->
    <BasicPaginator
      v-if="availableOrders"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalOrders"
    />
    <!-- #endregion::Pagination & items per list -->

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard, BCardText, BAlert, BFormCheckbox,
} from 'bootstrap-vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import CommissionsLitsFormFilters from '@/modules/trade/commissions/components/lists/CommissionsLitsFormFilters.vue'

import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import getError from '@/helpers/ErrorsHandler'
import validateCurrency from '@/helpers/CurrencyFormat'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import { statusBorderStyle, statusColorStyle } from '@/helpers/BannersStyles'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BAlert,
    BCardText,
    VueGoodTable,
    BFormCheckbox,
    BasicSkeleton,
    BasicPaginator,
    CommissionsLitsFormFilters,
  },
  props: {
    hideCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          field: 'checkbox',
          hidden: this.hideCheckbox,
        },
        {
          label: 'No.',
          field: 'no',
        },
        {
          label: 'Ubicación',
          field: 'location',
        },
        {
          label: 'Id',
          field: 'ID',
        },
        {
          label: 'Id cotización',
          field: 'quote_id',
        },
        {
          label: 'Estatus pago',
          field: 'payment_status',
        },
        {
          label: 'Estatus orden',
          field: 'order_status',
        },
        {
          label: 'Mes completada',
          field: 'completed_date',
        },
        {
          label: 'Subtotal MXN',
          field: 'subtotal_mxn',
        },
      ],
      orders: [],

      indexFrom: 0,
      totalOrders: 0,
      isLoadingOrders: false,

      /**
       * Filters
       */
      location: null,
      searchTerm: null,

      /**
       * Pagination
       */
      perPage: 10,
      lastPage: 0,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters({
      getCommission: 'commissions/getCommission',
      getIsCommissionEditing: 'commissions/getIsCommissionEditing',
      getCommissionOrdersIds: 'commissions/getCommissionOrdersIds',
      getSelectedCommissionOrders: 'commissions/getSelectedCommissionOrders',
      getSelectedCommissionSeller: 'commissions/getSelectedCommissionSeller',
      getSelectedCommissionMonthAndYear: 'commissions/getSelectedCommissionMonthAndYear',
    }),
    /**
     * Datos de la comisión
     */
    commission: {
      get() { return this.getCommission },
    },
    /**
     * Indica si el modo de edición está activo
     */
    isCommissionEditing: {
      get() { return this.getIsCommissionEditing },
    },
    /**
     * Indenticadores de las órdenes de la comisión
     */
    commissionOrdersIds: {
      get() { return this.getCommissionOrdersIds },
    },
    /**
     * Órdenes seleccionadas
     */
    selectedOrders: {
      get() { return this.getSelectedCommissionOrders },
    },
    /**
     * Filtro de creador (filtro en store)
     */
    filteredSeller: {
      get() { return this.getSelectedCommissionSeller },
    },
    /**
     * Filtro de mes y año (filtro en store)
     */
    filteredMonthAndYear: {
      get() { return this.getSelectedCommissionMonthAndYear },
    },
    /**
     * Órdenes con la bandera de seleccionado
     */
    formattedOrders() {
      return this.orders.map(order => ({
        ...order,
        selected: this.hideCheckbox
          ? false
          : this.selectedOrders.map(selectedOrder => selectedOrder.ID).includes(order.ID),
      }))
    },
    /**
     * Indica si hay elementos en la lista
     */
    availableOrders() {
      return this.totalOrders > 0
    },
  },

  /**
   * Hook de creación del componente
   * @summary Carga las órdenes de la comisión. Valida si las órdenes cargadas pertenecen
   * a las seleccionadas, es decir, las que deben mostrarse resaltadas
   */
  async created() {
    if (this.commission) {
      await this.loadOrdersListWithVerification()
    }
  },

  methods: {
    ...mapActions({
      loadOrders: 'commissions/loadOrders',
      addSelectedCommissionOrder: 'commissions/addSelectedCommissionOrder',
      removeSelectedCommissionOrder: 'commissions/removeSelectedCommissionOrder',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de pagos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'location': this.location = filter.value
          break
        default: this.searchTerm = filter.value
          this.currentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadOrdersListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadOrdersListWithVerification() {
      await this.loadOrdersList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.currentPage = currentPage
      this.perPage = perPage
      this.loadOrdersList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.orders.length === 0) {
        this.currentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableOrders) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadOrdersList()
        }
      }
    },

    /**
    * Carga de registros de tipo órdenes
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de órdenes y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de órdenes⁡
    */
    async loadOrdersList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingOrders = true
        const response = await this.loadOrders(this.loadParams())

        this.orders = response.data.data.data
        this.totalOrders = response.data.data.total
        this.indexFrom = response.data.data.from
        this.lastPage = response.data.data.last_page

        if (this.totalOrders === 0) {
          this.perPage = 10
        }

        if (this.isCommissionEditing) {
          this.orders.forEach(order => {
            // Se verifica si la orden actual está en la lista de órdenes seleccionadas
            if (this.commissionOrdersIds.includes(order.id_order)) {
              this.addSelectedCommissionOrder(order)
            }
          })
        }

        this.isLoadingOrders = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    /**
    * Parámetros de carga
    * @summary Devuelve los parámetros de carga de la lista. Se verifica si existe
    * una comisión cargada (detalle) para especificar los parámetros correctos.
    * @return {Object} - Parámetros para la carga de lista.
    */
    loadParams() {
      const baseParams = {
        showing: this.perPage,
        page: this.currentPage,
        search: this.searchTerm,
        location: this.location,
        commissions: 1,
      }

      const conditionalParams = this.commission
        ? {
          orders: this.commission.orders,
          ...(!this.isCommissionEditing && { detail: 1 }),
          ...(this.isCommissionEditing && {
            sort: '-ID',
            order_status: 8,
            completedDate: this.commission.month,
            payment_status: 2,
            user: this.commission.user_id,
          }),
        }
        : {
          sort: '-ID',
          order_status: 8,
          completedDate: this.filteredMonthAndYear.format('YYYY-MM-DD'),
          payment_status: 2,
          user: this.filteredSeller.id,
        }

      return { ...baseParams, ...conditionalParams }
    },

    /**
    * Validacion de datos numéricos
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },

    /**
    * Obtiene el estilo de border
    * @summary Obtiene el estilo de border de acuerdo al estatus de la orden
    * @param {String} status - Estatus de la orden
    * @return {String} Estilo de border
    */
    styleBorder(status) {
      return statusBorderStyle(status)
    },

    /**
    * Obtiene el estilo de color
    * @summary Obtiene el estilo de color de acuerdo al estatus de la orden
    * @param {String} status - Estatus de la orden
    * @return {String} Estilo de color
    */
    styleColor(status) {
      return statusColorStyle(status)
    },

    /**
    * Seleccion de órdenes
    * @summary Verifica la selección y deselección de órdenes que son consideradas
    * para el cálculo de comisiones. Agrega o elimina las órdenes seleccionadas del estado
    * @param {Object} order - Objeto de la orden
    */
    onOrderChecked(order) {
      if (order.selected) {
        this.addSelectedCommissionOrder(order)
      } else {
        this.removeSelectedCommissionOrder(order)
      }
    },

    /**
     * Estilo de fila
     * @summary Establece el estilo de la fila de acuerdo a la selección de la orden
     * @param {Object} row - Objeto de la ordnen
     * @return {String} Estilo de la fila
     */
    rowStyleClassFn(row) {
      return row.selected ? 'selected-row-success' : ''
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
