<template>
  <div>
    <CommissionsLitsFormFilters
      title="Devoluciones"
      @on:filter="handleFilter"
    />

    <!-- #region::Skeleton -->
    <template v-if="isLoadingRefunds">
      <BasicSkeleton height="400px" />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <vue-good-table
            v-if="availableRefunds"
            id="refunds-table"
            ref="commission-refunds-table"
            class="vgt-table-checkbox-styled"
            style-class="vgt-table condensed align-text-bottom"
            :columns="columns"
            :rows="formattedRefunds"
            :sort-options="{
              enabled: false,
            }"
            :row-style-class="rowStyleClassFn"
            compact-mode
          >

            <!-- #region::Empty response -->
            <div slot="emptystate">
              No hay devoluciones para mostrar.
            </div>
            <!-- #endregion::Empty response -->

            <!-- #region::Column headers -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span>
                <h6 class="capitalize-col-hd">
                  <strong>{{ props.column.label }}</strong>
                </h6>
              </span>
            </template>
            <!-- #endregion::Column headers -->

            <!-- #region::Modifying rows cells -->
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- #region::Checkbox -->
              <div v-if="props.column.field === 'checkbox'">
                <b-form-checkbox
                  v-model="props.row.selected"
                  @change="onRefundChecked(props.row)"
                />
              </div>
              <!-- #endregion::Checkbox -->

              <!-- #region::Row number -->
              <div v-if="props.column.field === 'no'">
                <h6>{{ indexFrom + props.index }}</h6>
              </div>
              <!-- #endregion::Row number -->

              <!-- #region:Location cell -->
              <div v-else-if="props.column.field === 'Location'">
                <template v-if="props.row.Location">
                  <div
                    :class="[styleBorder(props.row.Location), styleColor(props.row.Location)]"
                    role="alert"
                    class="text-nowrap"
                    style="display: inline; font-size: 11px;"
                  >
                    {{ props.row.Location }}
                  </div>
                </template>
                <span
                  v-else
                  class="text-nowrap"
                >Sin especificar</span>
              </div>
              <!-- #endregion:Location cell -->

              <!-- #region::ID cell -->
              <div
                v-else-if="props.column.field === 'ID'"
                style="font-size: 14px; margin-top: -1px;"
              >
                <router-link
                  :to="{ name: 'refund-details', params: { id: props.row.IdDevolution } }"
                  target="_blank"
                >
                  {{ props.row.ID }}
                </router-link>
              </div>
              <!-- #endregion::ID cell -->

              <!-- #region:Payment refund cell -->
              <div v-else-if="props.column.field === 'RefundPayment'">
                <template v-if="props.row.RefundPayment">
                  <div
                    :class="[styleBorder(props.row.RefundPayment), styleColor(props.row.RefundPayment)]"
                    role="alert"
                    class="text-nowrap"
                    style="display: inline; font-size: 11px;"
                  >
                    {{ props.row.RefundPayment }}
                  </div>
                </template>
                <h6
                  v-else
                  class="text-nowrap"
                >Sin especificar</h6>
              </div>
              <!-- #endregion:Payment refund cell -->

              <!-- #region:Refund status cell -->
              <div v-else-if="props.column.field === 'status'">
                <template v-if="props.row.status">
                  <div
                    :class="[styleBorder(props.row.status), styleColor(props.row.status)]"
                    role="alert"
                    class="text-nowrap"
                    style="display: inline; font-size: 11px;"
                  >
                    {{ props.row.status }}
                  </div>
                </template>
                <h6
                  v-else
                  class="text-nowrap"
                >Sin especificar</h6>
              </div>
              <!-- #endregion:Refund status cell -->

              <!-- #region:Received at cell -->
              <div v-else-if="props.column.field === 'received_at'">
                <h6
                  v-if="props.row.received_at "
                  class="text-nowrap"
                >{{ props.row.received_at | moment("MMMM YYYY") | capitalize }}</h6>
                <h6
                  v-else
                  class="text-nowrap"
                >Sin especificar</h6>
              </div>
              <!-- #endregion:Received at cell -->

              <!-- #region:Subtotal MXN cell -->
              <div v-else-if="props.column.field === 'TotalMxn'">
                <h6 class="text-nowrap font-weight-bolder">
                  {{ isValidCurrency(props.row.TotalMxn) }}
                </h6>
              </div>
              <!-- #endregion:Subtotal MXN cell -->

              <!-- #region::Custom general cell -->
              <div v-else>
                <h6 class="text-nowrap">
                  {{ props.formattedRow[props.column.field] }}
                </h6>
              </div>
            <!-- #endregion::Custom general cell -->

            </template>

          </vue-good-table>

          <!-- #region::Alert when no circuits are available -->
          <b-alert
            v-if="!availableRefunds"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron devoluciones.</span>
            </div>
          </b-alert>
        <!-- #endregion::Alert when no circuits are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region::Pagination & items per list -->
    <BasicPaginator
      v-if="availableRefunds"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalRefunds"
    />
    <!-- #endregion::Pagination & items per list -->

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard, BCardText, BAlert, BFormCheckbox,
} from 'bootstrap-vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import CommissionsLitsFormFilters from '@/modules/trade/commissions/components/lists/CommissionsLitsFormFilters.vue'

import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import getError from '@/helpers/ErrorsHandler'
import validateCurrency from '@/helpers/CurrencyFormat'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import { statusBorderStyle, statusColorStyle } from '@/helpers/BannersStyles'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BAlert,
    BCardText,
    VueGoodTable,
    BFormCheckbox,
    BasicSkeleton,
    BasicPaginator,
    CommissionsLitsFormFilters,
  },
  props: {
    hideCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          field: 'checkbox',
          hidden: this.hideCheckbox,
        },
        {
          label: 'No.',
          field: 'no',
        },
        {
          label: 'Ubicación',
          field: 'Location',
        },
        {
          label: 'Id',
          field: 'ID',
        },
        {
          label: 'Id orden',
          field: 'OrderID',
        },
        {
          label: 'Pago reembolso',
          field: 'RefundPayment',
        },
        {
          label: 'Estatus',
          field: 'status',
        },
        {
          label: 'Mes recibida',
          field: 'received_at',
        },
        {
          label: 'Subtotal MXN',
          field: 'TotalMxn',
        },
      ],
      refunds: [],

      indexFrom: 0,
      totalRefunds: 0,
      isLoadingRefunds: false,

      /**
       * Filters
       */
      location: null,
      searchTerm: null,

      /**
       * Pagination
       */
      perPage: 10,
      lastPage: 0,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters({
      getCommission: 'commissions/getCommission',
      getIsCommissionEditing: 'commissions/getIsCommissionEditing',
      getCommissionRefundsIds: 'commissions/getCommissionRefundsIds',
      getSelectedCommissionRefunds: 'commissions/getSelectedCommissionRefunds',
      getSelectedCommissionSeller: 'commissions/getSelectedCommissionSeller',
      getSelectedCommissionMonthAndYear: 'commissions/getSelectedCommissionMonthAndYear',
    }),
    /**
     * Datos de la comisión
     */
    commission: {
      get() { return this.getCommission },
    },
    /**
     * Indica si el modo de edición está activo
     */
    isCommissionEditing: {
      get() { return this.getIsCommissionEditing },
    },
    /**
     * Devoluciones seleccionadas
     */
    selectedRefunds: {
      get() { return this.getSelectedCommissionRefunds },
    },
    /**
     * Devoluciones seleccionadas
     */
    commissionRefundsIds: {
      get() { return this.getCommissionRefundsIds },
    },
    /**
     * Filtro de creador (filtro en store)
     */
    filteredSeller: {
      get() { return this.getSelectedCommissionSeller },
    },
    /**
     * Filtro de mes y año (filtro en store)
     */
    filteredMonthAndYear: {
      get() { return this.getSelectedCommissionMonthAndYear },
    },
    /**
     * Devoluciones con la bandera de seleccionado
     */
    formattedRefunds() {
      return this.refunds.map(refund => ({
        ...refund,
        selected: this.hideCheckbox
          ? false
          : this.selectedRefunds.map(selectedRefund => selectedRefund.ID).includes(refund.ID),
      }))
    },
    /**
     * Indica si hay elementos en la lista
     */
    availableRefunds() {
      return this.totalRefunds > 0
    },
  },

  /**
   * Hook de creación del componente
   * @summary Carga las devoluciones de la comisión. Valida si las devoluciones cargadas pertenecen
   * a las seleccionadas, es decir, las que deben mostrarse resaltadas
   */
  async created() {
    if (this.commission) {
      await this.loadRefundsListWithVerification()
    }
  },

  methods: {
    ...mapActions({
      loadRefunds: 'commissions/loadRefunds',
      addSelectedCommissionRefund: 'commissions/addSelectedCommissionRefund',
      removeSelectedCommissionRefund: 'commissions/removeSelectedCommissionRefund',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de pagos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'location': this.location = filter.value
          break
        default: this.searchTerm = filter.value
          this.currentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadRefundsListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadRefundsListWithVerification() {
      await this.loadRefundsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.currentPage = currentPage
      this.perPage = perPage
      this.loadRefundsList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.refunds.length === 0) {
        this.currentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableRefunds) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadRefundsList()
        }
      }
    },

    /**
    * Carga de registros de tipo devoluciones
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de devoluciones y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de devoluciones⁡
    */
    async loadRefundsList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingRefunds = true

        const response = await this.loadRefunds(this.loadParams())

        this.refunds = response.data.data.data
        this.totalRefunds = response.data.data.total
        this.indexFrom = response.data.data.from
        this.lastPage = response.data.data.last_page

        if (this.totalRefunds === 0) {
          this.perPage = 10
        }

        if (this.isCommissionEditing) {
          this.refunds.forEach(refund => {
          // Se verifica si la devolución actual está en la lista de devoluciones seleccionadas
            if (this.commissionRefundsIds.includes(refund.IdDevolution)) {
              this.addSelectedCommissionRefund(refund)
            }
          })
        }

        this.isLoadingRefunds = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    /**
    * Parámetros de carga
    * @summary Devuelve los parámetros de carga de la lista. Se verifica si existe
    * una comisión cargada (detalle) para especificar los parámetros correctos.
    * @return {Object} - Parámetros para la carga de lista.
    */
    loadParams() {
      const baseParams = {
        limit: this.perPage,
        'page[number]': this.currentPage,
        'filter[search]': this.searchTerm,
        locationDevolution: this.location,
        commissions: 1,
      }

      const conditionalParams = this.commission
        ? {
          refunds: this.commission.refunds,
          ...(!this.isCommissionEditing && { detail: 1 }),
          ...(this.isCommissionEditing && {
            sort: '-ID',
            status: 'Recibida',
            monthReceived: this.commission.month,
            refundPayment: 'Pagado',
            creator: this.commission.user_id,
          }),
        }
        : {
          sort: '-ID',
          status: 'Recibida',
          monthReceived: this.filteredMonthAndYear.format('YYYY-MM-DD'),
          refundPayment: 'Pagado',
          creator: this.filteredSeller.id,
        }

      return { ...baseParams, ...conditionalParams }
    },

    /**
    * Validacion de datos numéricos
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },

    /**
    * Obtiene el estilo de border
    * @summary Obtiene el estilo de border de acuerdo al estatus de la devolución
    * @param {String} status - Estatus de la devolución
    * @return {String} Estilo de border
    */
    styleBorder(status) {
      return statusBorderStyle(status)
    },

    /**
    * Obtiene el estilo de color
    * @summary Obtiene el estilo de color de acuerdo al estatus de la devolución
    * @param {String} status - Estatus de la devolución
    * @return {String} Estilo de color
    */
    styleColor(status) {
      return statusColorStyle(status)
    },

    /**
    * Seleccion de devoluciones
    * @summary Verifica la selección y deselección de devoluciones que son consideradas
    * para el cálculo de comisiones. Agrega o elimina las devoluciones seleccionadas del estado
    * @param {Object} refund - Objeto de la devolucion
    */
    onRefundChecked(refund) {
      if (refund.selected) {
        this.addSelectedCommissionRefund(refund)
      } else {
        this.removeSelectedCommissionRefund(refund)
      }
    },

    /**
     * Estilo de fila
     * @summary Establece el estilo de la fila de acuerdo a la selección de la devolución
     * @param {Object} row - Objeto de la devolución
     * @return {String} Estilo de la fila
     */
    rowStyleClassFn(row) {
      return row.selected ? 'selected-row-success' : ''
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
