/**
* Validador de valores porcentuales
* @summary Valida que el valor sea nulo, en caso de serlo devuelve N/A, en caso contrario
* devuelve el valor formateado como porcentaje
* @param {Number} value - Valor a validar
*/
function validatePercentage(value) {
  if (value === null) {
    return 'N/A'
  }

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return USDollar.format(value / 100)
}

export default validatePercentage
