import { render, staticRenderFns } from "./CommissionsResumenForm.vue?vue&type=template&id=2c59faff&scoped=true&"
import script from "./CommissionsResumenForm.vue?vue&type=script&lang=js&"
export * from "./CommissionsResumenForm.vue?vue&type=script&lang=js&"
import style0 from "./CommissionsResumenForm.vue?vue&type=style&index=0&id=2c59faff&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c59faff",
  null
  
)

export default component.exports