<template>
  <b-form-row>

    <!-- #region::User selected information -->
    <b-col
      md="6"
      class="mb-1"
    >
      <h4>Vendedor seleccionado</h4>
    </b-col>

    <b-col
      md="6"
      class="text-right mb-1"
    >
      <h4>Mes: {{ date | moment("MMMM YYYY") | capitalize }}</h4>
    </b-col>

    <b-col
      md="12"
      class="d-flex justify-content-right"
    >
      <div>
        <avatar
          username="Hola"
          :src="require('@/assets/images/avatars/user-av.png')"
          :size="60"
        />
      </div>
      <div class="ml-1">
        <h2>{{ name }}</h2>
        <h6 class="text-primary">
          {{ email }}
        </h6>
      </div>
      <!-- #endregion::User selected information -->
    </b-col>

  </b-form-row>
</template>

<script>
import { BCol, BFormRow } from 'bootstrap-vue'
import Avatar from 'vue-avatar'

export default {
  components: {
    BCol,
    Avatar,
    BFormRow,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
}
</script>
