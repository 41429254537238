<template>
  <div>

    <b-form-row align-v="center">

      <!-- #region::Return to commissions list link -->
      <b-col
        cols="12"
        class="px-0 mb-1"
      >
        <small>
          <router-link
            :to="{ name: 'commissions-home' }"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50 text-secondary"
            />
            <span class="align-middle text-secondary">Regresar</span>
          </router-link>
        </small>
      </b-col>
      <!-- #endregion::Return to commissions list link -->

      <!-- #region::Title with tag -->
      <b-col>
        <div class="d-flex justify-content-left">
          <h3 class="align-self-center">
            Comisión #{{ commissionId }}
          </h3>
          <div
            :class="[styleBorder('Commission'), styleColor(commissionStatus)]"
            role="alert"
          >
            {{ commissionStatus }}
          </div>
        </div>
      </b-col>
      <!-- #endregion::Title with tag -->

      <!-- #region::Download pdf button -->
      <b-col
        cols="12"
        md="auto"
      >
        <b-button
          variant="flat-success"
          class="csv-btn"
          :disabled="isDownloadingFile"
          @click="onDownloadPDF"
        >
          <b-spinner
            v-if="isDownloadingFile"
            class="mr-50"
            small
          />
          <feather-icon
            v-if="!isDownloadingFile"
            icon="DownloadIcon"
            class="mr-50"
          />
          <span class="align-middle">Descargar PDF</span>
        </b-button>
      </b-col>
      <!-- #endregion::Download pdf button -->

      <!-- #region::Cancel commission button -->
      <b-col
        v-if="canManageCommission"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="onCancelCommission"
        >
          <b-spinner
            v-if="isCancelingCommission"
            class="mr-50"
            small
          />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Cancel commission button -->

      <!-- #region::Edit commission button -->
      <b-col
        v-if="canManageCommission"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          @click="$router.push({ name: 'update-commission', params: { id: commissionId }})"
        >
          <feather-icon
            icon="Edit3Icon"
            class="mr-50"
          />
          <span class="align-middle">Editar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Edit commission button -->

    </b-form-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { modelBorderStyle, statusColorStyle } from '@/helpers/BannersStyles'

import {
  BCol, BFormRow, BButton, BSpinner,
} from 'bootstrap-vue'

import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BButton,
    BSpinner,
    BFormRow,
  },
  data() {
    return {
      /**
       * Indica si se esta descargando el archivo
       */
      isDownloadingFile: false,
      /**
       * Indica si se esta cancelando la comisión
       */
      isCancelingCommission: false,
    }
  },
  computed: {
    ...mapGetters({
      getCommission: 'commissions/getCommission',
    }),
    /**
     * Información de la comisión
     */
    commissionId() {
      return this.getCommission.commission_id
    },
    /**
     * Estatus de la comisión
     */
    commissionStatus() {
      return this.getCommission.status
    },
    /**
     * Obtiene la fecha del vendedor
     */
    sellerDate() {
      return this.getCommission?.month
    },
    /**
     * Obtiene el nombre del vendedor
     */
    sellerName() {
      return this.getCommission?.seller
    },
    canManageCommission() {
      return this.commissionStatus === 'Por pagar'
    },
  },
  methods: {
    ...mapActions({
      downloadPDFFile: 'commissions/downloadPDFFile',
      cancelCommission: 'commissions/cancelCommission',
    }),
    /**
    * Obtiene el estilo de border
    * @summary Obtiene el estilo de border de acuerdo al estatus de la orden
    * @param {String} status - Estatus de la orden
    * @return {String} Estilo de border
    */
    styleBorder(status) {
      return modelBorderStyle(status)
    },

    /**
    * Obtiene el estilo de color
    * @summary Obtiene el estilo de color de acuerdo al estatus de la orden
    * @param {String} status - Estatus de la orden
    * @return {String} Estilo de color
    */
    styleColor(status) {
      return statusColorStyle(status)
    },

    /**
     * Descarga el archivo PDF
     */
    async onDownloadPDF() {
      try {
        this.isDownloadingFile = true
        await this.downloadPDFFile({
          commissionId: this.commissionId,
          sellerDate: this.$options.filters.capitalize(this.$moment(this.sellerDate).format('MMMM YYYY')),
          sellerName: this.sellerName,
        })
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isDownloadingFile = false
      }
    },

    /**
     * Cancela la comisión
     */
    onCancelCommission() {
      this.$swal.fire({
        title: 'Cancelar comisión',
        text: '¿Estás seguro que desea cancelar el recibo? No podrá deshacer esta acción.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Regresar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.isCancelingCommission = true
          await this.cancelCommission(this.commissionId)
          this.isCancelingCommission = false
          this.$emit('reload-commission')
        }
      })
    },

    /**
    * Mensaje de alerta
    * @summary Muestra un mensaje de alerta personalizado
    * @param {string} title - Título del mensaje
    * @param {string} text - Texto del mensaje
    * @param {string} variant - Tipo de mensaje
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
